<template>
	<CoverageTemplate
		v-if="!$store.state.loading && loaded"
		:page-title="$t('title')"
		:page-sub-title="$t('description')"
		full-width
	>
		<MedicalExclusionAlert :show="medicalExclusion" />

		<ActionCards v-if="sortedBenefits.length > 0">
			<ActionCard
				v-for="benefit in sortedBenefits"
				:key="benefit.key"
				:icon="['fal', benefit.icon]"
				:route-name="benefit.route"
				:automation-id="benefit.key.toLowerCase()"
			>
				{{ benefit.label }}
			</ActionCard>
		</ActionCards>

		<BRow v-if="securityPolicy.individual === false">
			<BCol lg="9">
				<BenefitsBookletDownloadCard
					v-if="bookletId"
					class="mt-4 mb-5"
					:description="$t('booklet.description')"
					:download-link-name="$t('booklet.downloadLink')"
					automation-id="myCoverage"
				></BenefitsBookletDownloadCard>
			</BCol>
		</BRow>
	</CoverageTemplate>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue';
// mixins
import IdMixin from '@/mixins/id';
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
// pages
import CoverageTemplate from '@/pages/coverage/CoverageTemplate.vue';
// constants
import { DASHBOARD, COVERAGE } from '@/constants/Routes.js';
// components
import MedicalExclusionAlert from '@/components/common/alert/MedicalExclusionAlert';
import ActionCard from '@/components/common/card/ActionCard.vue';
import ActionCards from '@/components/common/card/ActionCards.vue';
import BenefitsBookletDownloadCard from '@/components/coverage/BenefitsBookletDownloadCard.vue';
// models
import Benefits from '@/models/coverage/BenefitsScepter.js';
import BenefitsBooklet from '@/models/coverage/BenefitsBooklet.js';

export default {
	components: {
		BRow,
		BCol,
		ActionCard,
		ActionCards,
		BenefitsBookletDownloadCard,
		MedicalExclusionAlert,
		CoverageTemplate
	},
	mixins: [IdMixin, BreadcrumbsManager],
	COVERAGE, // Set route constants on Vue instance object.  This allows using constants in template.
	data() {
		return {
			benefits: {},
			securityPolicy: {},
			loaded: false,
			bookletId: null
		};
	},
	computed: {
		medicalExclusion() {
			const exc = this.securityPolicy.medicalExclusion;
			return exc ? exc : false;
		},
		sortedBenefits() {
			let sortedBenefits = [];
			if (this.benefits) {
				for (const key of Object.keys(this.benefits)) {
					let objCardData;
					switch (key) {
						case 'DENTAL':
							objCardData = {
								key,
								label: this.$t('label.dental'),
								icon: 'tooth',
								route: this.$options.COVERAGE.DENTAL
							};
							break;
						case 'DRUGS':
							objCardData = {
								key,
								label: this.$t('label.drugs'),
								icon: 'capsules',
								route: this.$options.COVERAGE.DRUGS
							};
							break;
						case 'EHB':
							objCardData = {
								key,
								label: this.$t('label.ehb'),
								icon: 'heartbeat',
								route: this.$options.COVERAGE.EXTENDED_HEALTH
							};
							break;
						case 'HSA':
							objCardData = {
								key,
								label: this.$t('label.hsa'),
								icon: 'file-invoice-dollar',
								route: this.$options.COVERAGE.HSA
							};
							break;
						case 'HOSPITAL':
							objCardData = {
								key,
								label: this.$t('label.hospital'),
								icon: 'hospital-symbol',
								route: this.$options.COVERAGE.HOSPITAL
							};
							break;
						case 'LIFEDIS':
							objCardData = {
								key,
								label: this.$t('label.lifedis'),
								icon: 'shield-alt',
								route: this.$options.COVERAGE.LIFE_DISABILITY
							};
							break;
						case 'OPTBEN':
							objCardData = {
								key,
								label: this.$t('label.optben'),
								icon: 'umbrella',
								route: this.$options.COVERAGE.OPTIONAL_BENEFITS
							};
							break;
						case 'ONLINEDOCTOR':
							objCardData = {
								key,
								label: this.$t('label.onlinedoctor'),
								icon: 'user-md-chat',
								route: this.$options.COVERAGE.ONLINE_DOCTORS
							};
							break;
						case 'PWA':
							objCardData = {
								key,
								label: this.$t('label.pwa'),
								icon: 'hand-holding-usd',
								route: this.$options.COVERAGE.PWA
							};
							break;
						case 'RHIP':
							objCardData = {
								key,
								label: this.$t('label.rhip'),
								icon: 'envelope-open-dollar',
								route: this.$options.COVERAGE.RHIP
							};
							break;
						case 'TRAVEL':
							objCardData = {
								key,
								label: this.$t('label.travel'),
								icon: 'plane-departure',
								route: this.$options.COVERAGE.TRAVEL
							};
							break;
						case 'EFAP':
							objCardData = {
								key,
								label: this.$t('label.efap'),
								icon: 'hands-helping',
								route: this.$options.COVERAGE.EFAP
							};
							break;
					}

					let brand = JSON.parse(sessionStorage.getItem('securityPolicy')).brand;
					if (objCardData?.key === 'EFAP' && brand.toLowerCase() === 'sbc') {
						continue;
					} else {
						if (objCardData) sortedBenefits.push({ ...objCardData });
					}
				}
			}
			return sortedBenefits.sort((a, b) => {
				return a.label > b.label ? 1 : -1;
			});
		}
	},
	async created() {
		const email = sessionStorage.getItem('email');
		const token = sessionStorage.getItem('apiToken');
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.myCoverage', 'fr') }
			]
		);

		const hasBenefits = await Benefits.hasBenefits(email, this.$root.$i18n.locale);
		if (!hasBenefits) this.$store.dispatch('updateLoading', true);

		this.benefits = await Benefits.getBenefits(email, token, this.$root.$i18n.locale);
		this.securityPolicy = JSON.parse(sessionStorage.getItem('securityPolicy'));
		try {
			this.bookletId = await BenefitsBooklet.getBenefitsBookletId();
		} catch (e) {
			// this will show the booklet links but show error on fetching
			this.bookletId = null;
		}

		this.loaded = true;
		this.$store.dispatch('updateLoading', false);
	}
};
</script>

<i18n>
	{
	  "en": {
		"breadcrumb": {
		  "home": "Home",
		  "myCoverage": "Plan Coverage"
		},
		"title": "Check My Coverage",
		"description": "Choose a type of coverage to see what's available to you. Each participant on your plan may have different coverage.",
		"label": {
		  "dental": "Dental",
		  "hospital": "Hospital",
		  "pwa": "Personal Wellness Account (PWA)",
		  "drugs": "Drugs",
		  "lifedis": "Life & Disability Benefits",
		  "travel": "Travel",
		  "ehb": "Extended Health Benefits",
		  "efap": "inConfidence (EFAP)",
		  "optben": "Optional Benefits",
		  "hsa": "Health Spending Account (HSA)",
				"onlinedoctor": "Online Doctors",
				"rhip": "Retirement Health Insurance Program (RHIP)"
		},
		"booklet": {
		  "description": "Download your benefits booklet for a full overview of your current plan and all your benefits.",
		  "downloadLink":"Download your complete coverage details (PDF)"
		}
	  },
	  "fr": {
		"breadcrumb": {
		  "home": "Accueil",
		  "myCoverage": "Couverture du régime"
		},
		"title": "Vérifier ma couverture",
		"description": "Sélectionnez une garantie pour en savoir plus sur les produits et services couverts. Chaque personne assurée par votre régime pourrait avoir une couverture différente.",
		"label": {
		  "dental": "Soins dentaires",
		  "hospital": "Hospitalisation",
		  "pwa": "Compte Gestion mieux-être (CGM)",
		  "drugs": "Assurance médicaments",
		  "lifedis": "Assurance vie et assurance invalidité",
		  "travel": "Assurance voyage",
		  "ehb": "Soins de santé complémentaires",
		  "efap": "enÉquilibre (PAEF)",
		  "optben": "Garanties facultatives",
		  "hsa": "Compte Gestion-santé (CGS)",
				"onlinedoctor": "Médecin en ligne",
				"rhip": "Régime d'assurance maladie à la retraite (RAMR)"
		},
		"booklet": {
		  "description": "Téléchargez votre brochure de garanties pour obtenir un portrait global de votre régime et de ses garanties.",
		  "downloadLink":"Téléchargez les renseignements complets sur votre couverture (PDF)"
		}
	  }
	}
</i18n>
