<!--
  Parent for all Coverage pages
-->
<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'Coverage'
};
</script>
